var Cameraland = Cameraland || {};

Cameraland.CheckRadio = class {
    constructor(options) {
        this.addSpan = this.addSpan.bind(this);

        this.options = $j.extend({}, {
            checkSelector: 'input[type=checkbox]',
            radioSelector: 'input[type=radio]',
            inputBoxSelector: 'input-box',
            labelSelector: 'label',
        }, options);

        this.addSpan();
    }

    addSpan() {
        $j(this.options.checkSelector).parent().find(this.options.labelSelector).append('<span class="holder"></span>');
        $j(this.options.radioSelector).parent().find(this.options.labelSelector).append('<span class="holder"></span>');
    }
};

$j(function () {
    new Cameraland.CheckRadio();
});